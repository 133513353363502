export default {
  "two-column-layout": "Wk",
  "points-list": "WJ",
  "point-title": "Ws",
  "point-content": "WF",
  "old-container": "Wg",
  "columns": "WD",
  "left-column": "WV",
  "right-column": "WL",
  "video-wrapper": "WT",
  "selling-points-wrapper": "Wz",
  "actions": "Wa"
};
