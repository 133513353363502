export default {
  "checks": "hu",
  "content": "fS",
  "form": "fc",
  "title": "fq",
  "description": "fZ",
  "wrapper": "fR",
  "aside": "fQ",
  "amount-field": "fe"
};
