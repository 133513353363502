export default {
  "container": "AA",
  "container-metal": "AY",
  "content": "Ah",
  "button": "Af",
  "embossed-selector": "AK",
  "carousel-container": "AG",
  "carousel": "Ar",
  "asset": "Ab",
  "poster": "Ay",
  "video-embossed": "AU",
  "video": "Aj",
  "active": "AW",
  "embossed": "Au",
  "safari": "YS",
  "poster-open": "Yc"
};
