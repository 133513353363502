export default {
  "invite-review": "yZ",
  "invite-review-info": "yR",
  "invite-review-info--invitee": "yQ",
  "disclaimer-button": "ye",
  "info-block": "yB",
  "ev": "yE",
  "tooltip": "yd",
  "description": "yI"
};
