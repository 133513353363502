export default {
  "animated": "As",
  "container": "AF",
  "header": "Ag",
  "footer": "AD",
  "skip-cta": "AV",
  "pricing-caption": "AL",
  "asset-x": "AT",
  "asset-plus": "Az",
  "asset-one": "Aa",
  "show": "AH",
  "selector": "AO",
  "selectorFadeIn": "Am",
  "fadeIn": "Ap",
  "onePlusAssetFadeIn": "Ax",
  "assetMoveUp": "Aw",
  "xAssetFadein": "AN",
  "feature": "Av",
  "highlighted": "Ao"
};
