export default {
  "wrapper": "WW",
  "confirm": "Wu",
  "plan-info": "uS",
  "plan-info-left": "uc",
  "info-title": "uq body-1",
  "initial-trial-content": "uZ",
  "extra-fees-item": "uR body-2",
  "extra-fees-container": "uQ",
  "bottom-wrapper": "ue",
  "total-price-container": "uB",
  "extra-fees-element": "uE body-1",
  "border": "ud",
  "subtitle": "uI body-2",
  "group-item": "ut",
  "amount": "uM",
  "subscription-billing-summary": "uP",
  "summary": "ul",
  "summary-list": "uX",
  "disclaimer": "uC",
  "disclaimer-revamp": "uk",
  "error": "uJ",
  "error-link": "us",
  "warning-wrapper": "uF",
  "warning-label": "ug body-1"
};
