export default {
  "container": "op",
  "summary": "ox",
  "sideview": "ow",
  "steps-container": "oN",
  "invoice-upload-container": "ov",
  "pdf-preview": "oo",
  "uploader": "on",
  "template-text": "oi",
  "file-dropzone": "oA",
  "overlay": "oY",
  "footer": "oh",
  "upload-prompt": "of",
  "step-selector": "oK",
  "disclaimer": "oG"
};
