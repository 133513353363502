export default {
  "guests-loading": "cRU",
  "header": "cRj",
  "body": "cRW",
  "members": "cRu",
  "title": "cQS caption-bold",
  "placeholder-container": "cQc",
  "icon": "cQq",
  "details": "cQZ"
};
