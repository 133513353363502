export default {
  "wrapper": "be",
  "promotion-title": "bB",
  "comparison-table": "bE",
  "table-head": "bd",
  "title-wrapper": "bI",
  "illustration-wrapper": "bt",
  "illustration": "bM",
  "role-wrapper": "bP",
  "role-name": "bl",
  "role-disabled": "bX",
  "role-access": "bC",
  "disabled-caption": "bk",
  "table-head-sticky": "bJ",
  "show-border": "bs",
  "group-row": "bF",
  "table-body": "bg",
  "group": "bD",
  "table-body-row": "bV",
  "feature-description": "bL body-2",
  "feature": "bT body-2",
  "group-spacer": "bz"
};
