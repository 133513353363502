export default {
  "container": "nS",
  "customize-field": "nc",
  "content": "nq",
  "sideview": "nZ",
  "form": "nR",
  "footer": "nQ",
  "layout": "ne",
  "pdf-preview": "nB",
  "german-preview": "nE"
};
