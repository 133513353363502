export default {
  "container": "Yi",
  "disabled-option": "YA",
  "content": "YY",
  "spinner": "Yh",
  "subtitle": "Yf",
  "details-table": "YK",
  "caption": "YG",
  "tooltip-icon": "Yr",
  "tooltip-icon-right": "Yb",
  "card-monthly-cost": "Yy",
  "card-monthly-discounted-cost": "YU",
  "strikethrough": "Yj",
  "purple-text": "YW",
  "budget-label": "Yu",
  "card-cost-label": "hS",
  "card-billing-account": "hc"
};
