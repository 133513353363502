/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
import { service, type Registry as Services } from '@ember/service';
import Component from '@glimmer/component';

import { Success } from '@repo/domain-kit/financing';

interface FlowsFinancingPayLaterTopupSuccess {
  // The arguments accepted by the component
  Args: {};
  // Any blocks yielded by the component
  Blocks: {
    default: [];
  };
  // The element to which `...attributes` is applied in the component template
  Element: null;
}

export default class FlowsFinancingPayLaterTopupSuccessComponent extends Component<FlowsFinancingPayLaterTopupSuccess> {
  @service declare segment: Services['segment'];

  Success = Success;

  constructor() {
    // @ts-expect-error
    super(...arguments);
    this.segment.track('pay-later-cockpit_repay-by-card_success');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Flows::Financing::PayLater::Topup::Success': typeof FlowsFinancingPayLaterTopupSuccessComponent;
  }
}
