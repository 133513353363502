export default {
  "summary-description": "cqq",
  "detail": "cqZ",
  "detail-value": "cqR",
  "account-icon": "cqQ",
  "color-secondary": "cqe",
  "beneficiary-info": "cqB",
  "currency": "cqE",
  "beneficiary-name": "cqd",
  "separator": "cqI",
  "instant-subtitle": "cqt",
  "instant-wrapper": "cqM",
  "instant-header": "cqP",
  "instant-toggle": "cql",
  "instant--disabled": "cqX",
  "limit-exceeded-disclaimer": "cqC",
  "sticky-panel": "cqk",
  "total-interest": "cqJ",
  "tooltip-icon": "cqs",
  "tooltip": "cqF",
  "payment-options": "cqg",
  "starting-on-value": "cqD",
  "standard-transfer-tooltip": "cqV"
};
