export default {
  "container": "nf",
  "container-content": "nK",
  "content": "nG",
  "underline": "nr",
  "inline-content": "nb",
  "payment-rate": "ny",
  "payment-subtitle": "nU",
  "grey-text": "nj",
  "earnings-header": "nW",
  "earnings-tooltip": "nu"
};
