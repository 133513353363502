export default {
  "container": "vs",
  "company-details": "vF",
  "sideview": "vg",
  "form": "vD",
  "french-company-details-fieldset": "vV",
  "footer": "vL",
  "pdf-preview": "vT",
  "de-layout": "vz",
  "de-pdf-preview": "va",
  "german-preview": "vH"
};
