export default {
  "step-container": "KM",
  "content": "KP",
  "detail": "Kl",
  "secondary": "KX",
  "reference": "KC",
  "fees-label": "Kk",
  "fees-tooltip": "KJ",
  "separator": "Ks",
  "mt-32": "KF"
};
