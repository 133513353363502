export default {
  "voucher-wrapper": "cSf",
  "left-side": "cSK",
  "left-side__wrapper": "cSG",
  "voucher-illustration": "cSr",
  "left-side__wrapper-title": "cSb",
  "voucher-list": "cSy",
  "left-side__wrapper-details": "cSU",
  "right-side": "cSj",
  "multi-accounts": "cSW",
  "right-side__wrapper": "cSu"
};
