export default {
  "container": "ot",
  "numbering": "oM",
  "sideview": "oP",
  "form": "ol",
  "numbering-fields": "oX",
  "row": "oC",
  "label-tooltip": "ok",
  "tooltip-icon": "oJ",
  "legend": "os",
  "next-invoice-number": "oF",
  "pdf-preview": "og",
  "de-layout": "oD",
  "de-pdf-preview": "oV"
};
