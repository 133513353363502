export default {
  "header": "No",
  "header-top": "Nn",
  "header-inner": "Ni",
  "disclaimer": "NA mb-16",
  "header-main-wrapper": "NY",
  "header-main": "Nh",
  "logo": "Nf mr-16",
  "header-right": "NK",
  "partner-description": "NG body-2"
};
