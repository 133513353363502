export default {
  "color-option": "cZV",
  "color-dot": "cZL",
  "any": "cZT",
  "red": "cZz",
  "orange": "cZa",
  "yellow": "cZH",
  "green": "cZO",
  "blue": "cZm",
  "purple": "cZp",
  "pink": "cZx",
  "black": "cZw",
  "grey": "cZN",
  "brown": "cZv",
  "color-name": "cZo"
};
