export default {
  "step-container": "US",
  "flex-table": "Uc",
  "flex-row": "Uq",
  "flex-cell": "UZ",
  "with-border": "UR",
  "with-border-top": "UQ",
  "secondary": "Ue",
  "flex-info": "UB",
  "amount": "UE",
  "legal-name": "Ud"
};
