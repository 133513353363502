export default {
  "mandate-new-no-mandate": "re",
  "left-side": "rB",
  "left-side__wrapper": "rE",
  "left-side__wrapper-title": "rd",
  "left-side__wrapper-details": "rI",
  "left-side__wrapper-details-item": "rt",
  "right-side": "rM",
  "multi-accounts": "rP",
  "right-side__wrapper": "rl",
  "right-side__wrapper-name": "rX",
  "right-side__wrapper-address": "rC",
  "right-side__wrapper-iban-dashed": "rk",
  "right-side__wrapper-address-label": "rJ",
  "right-side__wrapper-iban-label": "rs",
  "right-side__wrapper-bic-label": "rF",
  "right-side__wrapper-address-dashed": "rg",
  "right-side__wrapper-bic-dashed": "rD",
  "dropdown-icon": "rV",
  "dropdown": "rL",
  "multi-accounts-illustration": "rT"
};
