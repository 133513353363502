export default {
  "container": "WB",
  "form-core": "WE",
  "label": "Wd",
  "request-description": "WI",
  "radio-option": "Wt",
  "radio-button": "WM",
  "inline-inputs": "WP",
  "placeholder-label": "Wl",
  "placeholder-input": "WX",
  "placeholder-checkbox": "WC"
};
