export default {
  "wrapper": "cRq",
  "close-button": "cRZ",
  "content-wrapper": "cRR",
  "main": "cRQ",
  "main-content": "cRe",
  "disclaimer": "cRB",
  "description": "cRE",
  "mt-26": "cRd",
  "mt-62": "cRI",
  "user-prompt": "cRt",
  "user-prompt-inappropriate-warning": "cRM",
  "logo-preview": "cRP",
  "static-loader": "cRl",
  "loading-state-illustration": "cRX",
  "sticky-panel": "cRC"
};
