export default {
  "container": "YB",
  "centered": "YE",
  "content": "Yd",
  "spinner": "YI",
  "holder-selector-wrapper": "Yt mb-16",
  "holder-selector-error": "YM",
  "info-text": "YP",
  "error-text": "Yl",
  "lottie-illustration": "YX",
  "info-text-1": "YC",
  "info-text-2": "Yk"
};
