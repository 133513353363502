export default {
  "journey": "jk",
  "content": "jJ",
  "distance": "js",
  "google-link-container": "jF",
  "link-icon": "jg",
  "continue-btn": "jD",
  "origin": "jV",
  "input-width": "jL"
};
