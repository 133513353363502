export default {
  "container": "Go",
  "content": "Gn",
  "buttons": "Gi",
  "genders": "GA",
  "birth-details": "GY",
  "has-error": "Gh",
  "gender-option": "Gf",
  "radio-button": "GK"
};
