export default {
  "content": "cSC",
  "error-message": "cSk",
  "wrapper": "cSJ",
  "banner": "cSs",
  "banner-content": "cSF",
  "banner-text": "cSg",
  "banner-image": "cSD",
  "banner-title": "cSV",
  "banner-description": "cSL body-2",
  "link": "cST",
  "plans-comparison": "cSz",
  "plan-box": "cSa",
  "arrow": "cSH",
  "arrow-icon": "cSO",
  "link-downgrade": "cSm",
  "stroke-text": "cSp"
};
