export default {
  "details": "Uy",
  "disclaimer": "UU",
  "data": "Uj",
  "confirm": "UW",
  "form": "Uu",
  "textarea": "jS",
  "preview": "jc",
  "form-title": "jq title1",
  "form-subtitle": "jZ title2",
  "divider": "jR",
  "submit": "jQ",
  "doc": "je",
  "amount-input-error": "jB"
};
