export default {
  "step-container": "ccG",
  "content": "ccr",
  "sticky-panel": "ccb",
  "banner": "ccy",
  "banner-content": "ccU",
  "banner-text": "ccj",
  "banner-image": "ccW",
  "banner-title": "ccu",
  "banner-description": "cqS body-2",
  "link": "cqc"
};
