export default {
  "label-wrapper": "hN body-1",
  "container": "hv",
  "transaction-info": "ho",
  "secondary": "hn",
  "amount-container": "hi",
  "amount": "hA",
  "disabled": "hY",
  "checkbox": "hh",
  "disclaimer": "hf"
};
