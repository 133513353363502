export default {
  "wrapper": "cZS input-field mb-4",
  "error": "cZc",
  "inactive": "cZq",
  "hidden-value": "cZZ",
  "percentage-sign": "cZR",
  "black": "cZQ",
  "input-field": "cZe",
  "disabled": "cZB"
};
