export default {
  "row": "Nj",
  "active": "NW",
  "cell": "Nu",
  "empty": "vS",
  "supplier": "vc",
  "supplier-content": "vq",
  "declined": "vZ",
  "total-repayment": "vR body-1",
  "amount": "vQ",
  "supplier-avatar": "ve mr-16",
  "financing-status": "vB body-2",
  "installments": "vE body-2",
  "overflow-hidden": "vd",
  "ellipsis": "vI",
  "completed-on": "vt",
  "financed-amount": "vM body-2"
};
