export default {
  "step-container": "Ko",
  "content": "Kn",
  "section": "Ki",
  "section-content": "KA",
  "section-content-text": "KY",
  "section-illustration": "Kh",
  "close-form-cta": "Kf",
  "bank-account-select": "KK"
};
