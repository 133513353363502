export default {
  "wrapper": "Wn",
  "wrapper-revamp": "Wi",
  "container": "WA",
  "container-revamp": "WY",
  "box-wrapper": "Wh",
  "warning-label": "Wf",
  "subtitle-container": "WK",
  "info-container": "WG",
  "card": "Wr",
  "disclaimer": "Wb",
  "list": "Wy",
  "list-item": "WU body-2",
  "total": "Wj"
};
