export default {
  "step-container": "yp",
  "subtitle": "yx",
  "step-container-content": "yw",
  "continue-button": "yN",
  "period-select": "yv",
  "period-dropdown": "yo",
  "readonly": "yn",
  "period-error": "yi",
  "toggle-options-container": "yA",
  "receipt-code-toggle-input": "yY"
};
