export default {
  "container": "cZA",
  "container--collapsed": "cZY",
  "spin": "cZh",
  "cta-tooltip": "cZf",
  "cta--collapsed": "cZK",
  "cta--expanded": "cZG",
  "collapsed-svg-icon": "cZr",
  "expanded-svg-icon": "cZb",
  "generate-logo-cta": "cZy",
  "badge": "cZU",
  "scaleDown": "cZj",
  "fadeIn": "cZW",
  "textRiseButton": "cZu",
  "cta-description": "cRS",
  "textRiseDescription": "cRc"
};
