export default {
  "wrapper": "ik",
  "wrapper-new": "iJ",
  "icon-list": "is",
  "list-item": "iF",
  "box-wrapper": "ig",
  "panel": "iD",
  "panel-top": "iV",
  "list": "iL",
  "container": "iT",
  "column": "iz",
  "icon": "ia",
  "column-right": "iH"
};
