export default {
  "container": "uD",
  "has-background": "uV",
  "wrapper": "uL",
  "error-wrapper": "uT",
  "bottom-btns": "uz",
  "bottom-wrapper": "ua",
  "tab-nav": "uH",
  "tab-nav-small": "uO",
  "btn-clear": "um",
  "error-illustration": "up",
  "error-title": "ux title-1",
  "disclaimer-wrapper": "uw",
  "disclaimer": "uN",
  "error-description": "uv body-2",
  "table-wrapper": "uo",
  "title": "un title-1",
  "lineup-toggle": "ui",
  "description": "uA body-2",
  "loading-wrapper": "uY",
  "card": "uh",
  "plan": "uf",
  "placeholder-header": "uK",
  "disclaimer-info": "uG",
  "spinner": "ur"
};
